import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { Row, Col } from "antd";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import Login from './views/login';
import Setup from './components/SetupCode';
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

ReactDOM.render(
  // <React.StrictMode>
    // <Row
    //   style={{
    //     backgroundColor: "#F9FBFF",
    //     borderRadius: "20px",
    //   }}
    // >
    //   <App />
    // </Row>
    <Router>
      <Switch>
        <Route path="/dashboard">
          <Row
            style={{
              backgroundColor: "#F9FBFF",
              borderRadius: "20px",
            }}
          >
            <App />
          </Row>
        </Route>
        <Route path="/setup/:slug">
          <Setup />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
    
  // </React.StrictMode>
  ,document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
